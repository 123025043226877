import React from 'react';
import Hero from '../components/Hero';
import ContactUs from '../components/ContactUs';
const Home = () => {

  return (
  <div>
    <Hero />
    <ContactUs/>
  </div>
  );
};

export default Home;
