import React, { useContext, useEffect, useState } from 'react';
//sidebar context
import { SidebarContext} from '../contexts/SidebarContext';

//sidebar context
import { CartContext} from '../contexts/CartContext';
//import icons
import {BsBag} from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from '../img/logo.svg';
const Header = () => {

  const [isActive , setIsActive] = useState(false)
  const {isOpen, setIsOpen} = useContext(SidebarContext);
  const {itemAmount} = useContext(CartContext);
  // event listener
  useEffect(()=>{
    window.addEventListener('scroll' , ()=>{
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false)
    });
  });
  return <header className={`${isActive ? 'bg-white py-4 shadow-md' : 'bg-none py-6'} fixed w-full z-10 transition-all`}>
    <div className='container mx-auto flex items-center h-full'>
      {/** logo*/}
      <HashLink to={'/#home'} className='ml-10'>
        Home
      </HashLink>

    <Link to={'/products'}>
        <div className='ml-10'>
          {/* <img className='w-[40px]' src={Logo} alt=''/> */}
          Products
        </div>
      </Link>
    
      <HashLink to={'/#Contact-Us'} className='ml-10'>
        Contact Us
      </HashLink>
      {/**cart */}
      <div onClick={()=> setIsOpen(!isOpen)}
      className='cursor-pointer flex relative ml-auto'>
        <BsBag className='text-2x1'/>
        <div className='bg-red-500 absolute -right-2  -button-2 text-[12px] w-[18px] h-[18px] text-white rounded-full flex justify-center itesm-center'>
          {itemAmount}
        </div>
      </div>
    </div>
   
  </header>;
};

export default Header;
