import React, { useState } from 'react';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div id='Contact-Us' className="contact-us  py-24 gap-20 justify-center items-center flex flex-col ">
      <h2 className='text-[60px] leading-[1.1] font-light mb-4'>Contact Us</h2>
      <p>For any Query E-mail Us at <a href="mailto:xyz@example.com">xyz@example.com</a>.</p>
    </div>
  );
}

export default ContactUs;